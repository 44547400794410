:root {
  --bg-sidebar-navbar-light-mode: #ccc;
  --bg-sidebar-navbar-dark-mode: #141824; 
  
  --color-sidebar-navbar-light-mode: #212529; /*boostrap 5 table-dark*/
  --color-sidebar-navbar-dark-mode:#f8f9fa;

  --bg-sidebar-hover-light-mode: #fff;
  --bg-sidebar-hover-dark-mode: #333;

  --color-sidebar-hover-light-mode:#0A58CA;
  --color-sidebar-hover-dark-mode:orange;

  --color-text-light-mode: #212529;
  --color-text-dark-mode: #f8f9fa;

  --bg-outlet-light-mode: #fff;
  --bg-outlet-dark-mode: #212529;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body{
  height: 100vh;
  /* Añade una transición suave para el cambio de color */
  transition: background-color 0.5s ease; 
  /*color: #f8f9fa; */ /*boostrap 5 text-light*/
}

.dark-mode {
  background-color: var(--bg-sidebar-navbar-dark-mode);
  color: var(--color-text-dark-mode) !important;
}

.light-mode {
  background-color: var()var(--bg-sidebar-navbar-light-mode);
  color: var(--color-text-light-mode) !important;
}

.outlet-dark-mode {
  background-color: var(--bg-outlet-dark-mode);
  color: var(--color-text-dark-mode) !important;
}

.outlet-light-mode {
  background-color: var()var(--bg-outlet-light-mode);
  color: var(--color-text-light-mode) !important;
}

.main{
  display: flex;
  height: 100vh;
}

/* ------------------------------------- */
/* -------------- SIDEBAR -------------- */
/* ------------------------------------- */

.sidebar{
  height: 100%;
  width: 160px; /*50*/
  flex-shrink: 0;
}

.sidebar-light-mode{
  background-color: var(--bg-sidebar-navbar-light-mode);
  color: var(--color-sidebar-navbar-light-mode);
}

.sidebar-dark-mode{
  background-color: var(--bg-sidebar-navbar-dark-mode);
  color: var(--color-sidebar-navbar-dark-mode);
}

.sidebar-item{
  padding-left: 10px;
  padding-right: 10px;
  padding-top:3px;
  padding-bottom:3px;
  display: block;
  text-decoration: none;
}

.sidebar-item-active{
  padding-left: 10px;
  padding-right: 10px;
  padding-top:3px;
  padding-bottom:3px;
  display: block;
  text-decoration: none;
}

.sidebar-item-dark-mode{
  color: var(--color-sidebar-navbar-dark-mode);
}

.sidebar-item-dark-mode:hover{
  color: var(--color-sidebar-hover-dark-mode);
  background-color: var(--bg-sidebar-hover-dark-mode);
}

.sidebar-item-active-dark-mode{
  color: var(--color-sidebar-hover-dark-mode);
}

.sidebar-item-light-mode{
  color: var(--color-sidebar-navbar-light-mode);
}

.sidebar-item-light-mode:hover{
  color: var(--color-sidebar-hover-light-mode);
  background-color: var(--bg-sidebar-hover-light-mode);
}

.sidebar-item-active-light-mode{
  color:var(--color-sidebar-hover-light-mode);
}

.sidebar_title_icon{
  width: 30px;
  display: flex;
  font-size: 16px;
}

.sidebar_title_text{
  display: flex;
}

.sidebar-title{
  display: flex;
  align-items: center;
}

.sidebar_menu_icon{
  width: 24px;
}

.sidebar_menu_text{
  width: 100px;
  font-size: 12px;
  justify-content: space-between;
}

.sidebar-title span{
  display: flex;
}

.sidebar-title span i{
  display: inline-block;
  width: 25px;
}

.sidebar-title i{
  cursor: pointer;
  transition: transform 0.5s;
}

.sidebar-item.active .sidebar-title > i{
  transform: rotate(180deg);;
}

.sidebar-content{
  padding-top: 5px;
  height: 0;
  overflow: hidden;
  transition: all 0.5s;
}

.sidebar-item.active .sidebar-content{
  height: auto;
  display: flex;
  flex-direction: column;
}
/* ------------------------------------- */
/* -------------- NAVBAR --------------- */
/* ------------------------------------- */

.navbar-dark-mode{
  background-color: var(--bg-sidebar-navbar-dark-mode);
  color: var(--color-sidebar-navbar-dark-mode);
}

.navbar-light-mode{
  background-color: var(--bg-sidebar-navbar-light-mode);
  color: var(--color-sidebar-navbar-light-mode);
}

/* ------------------------------------- */
/* ------------------------------------- */
/* ------------------------------------- */

.top_section{
  display: flex;
  align-items:center;
  /*padding:20px 15px;*/
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 5px;
}

.logo{
  font-size: 20px;
}

.bars{
  cursor: pointer;
  /*display: flex;*/
  font-size: 20px;
  width: 30px;
  display: flex;
}

.principal{ 
  width: 100%;
  display: flex;
  
  justify-content: center;
  align-items: center;
  border:1px solid #333333;
}

.swal2-popup{
  background-color: #333 !important;
}

/* color de 'text' en sweetalert2*/
.swal2-html-container{
  color: #EEE !important;
  font-size:medium !important;
}

/*cambiar propiedades del título*/
.swal2-title{
  color: #EEE !important;
  font-size:larger !important;
  padding: .1em 1em 0 !important;
}

/*cambiar propiedades del ícono (para que se vea mas pequeño)*/
.swal2-icon {
  width: 3em !important;
  height: 3em !important;
  margin: 0.5em auto .4em !important;
}

.swal2-actions{
  margin: .25em auto 0 !important;
}

/*cambiar tamaño de los botones*/
.swal2-styled{
  padding: .4em 1.1em !important;
}

/* ----------------------------------------- */

table tr th {
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.sorting {
  background-color: #222222;
}

.asc:after {
  content: ' ↑';
}

.desc:after {
  content: " ↓";
}

/*Legende de un <fieldset>*/
legend {
  top: -0.5rem; /* Ajusta este valor según sea necesario */
  left: 0.5rem; /* Ajusta este valor según sea necesario */
  padding: 0 0.2rem; /* Añade padding izquierdo y derecho */
  /*border: 1px solid #e0e0e0;*/ /* Añade un borde para separar del contenido */
  font-size: 0.7rem;
  line-height: 1;
}

/*Colores calendario*/
/* .fc-daygrid-day-number {
  color: #DDDDDD;
} */

/*color días*/
/* .fc-col-header-cell-cushion {
  color: #DDDDDD; 
} */

/* .fc-toolbar-title {
  font-size: 1.2em;
} */

/* .fc-col-header-cell-cushion{
  color: #ddd !important; 
} */

.fc {
  max-height: 700px; /* maxHeight calendario completo */
  overflow-y: auto; /* Agrega una barra de desplazamiento vertical si el contenido supera el maxHeight */
}

/*******************************/

.react-datepicker-popper {
  z-index: 1030 !important; /*Para que el calendario no se corte cuando se ve sobre una tabla con sticky-top*/
}

/*******************************/

.dropdown-menu{
  z-index:1040 !important; /*Para que el botón Cerrar Sesión no queda debajo de una table con header sticky-top*/
}

/*******************************/
.bg-btn-close-modal{
  background-color: #999;
}